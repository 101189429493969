<template>
  <span class="d-flex">
    <v-chip
      :color="run.statusColor"
      small
      outlined
      class="mr-3"
    >
      {{run.msg}}
    </v-chip>
  </span>

</template>

<script>
export default {
  computed: {
  },
  props: {
    run: Object
  }
}
</script>
